import axios from 'axios';

let data = {}

const load = async () => 
    axios
        .get("https://blab.coctelcreativo.com/data/cartadigital.php")
        .then(res => {
            data = res.data;
        return true;
});

const get = () =>{
    return data;
}

export default {
    load,
    get
}